<template>
  <!-- 关键词 文章列表 -->
  <div class="container">
    <!-- 内容 -->
    <div class="center">
      <div class="content">
        <!-- 快讯详细信息 -->
        <div class="left">
          <!-- 文章列表 -->
          <div class="list">
            <div
              v-for="(news, index) in list"
              :key="news.id"
            >
              <V-OneNewsFlash
                :title="news.title"
                :content="news.content"
                :time="news.time"
                :id="news.id"
                :list ="list"
                :hotNum ="news.hotNum"
              ></V-OneNewsFlash>
              <hr v-if="index + 1 != list.length" />
            </div>
          </div>
          <br />
          <!-- 分页 -->
          <div class="page-nav">
            <el-pagination
              background
              layout="prev,pager,next"
              :hide-on-single-page="hidePageNav"
              :total="totlePage"
              :page-size="pageSize"
              @prev-click="prevPage"
              @next-click="nextPage"
              @current-change="currentPage"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 24小时热榜 -->
        <div class="right">
          <div style="width:100%;">
            <V-Right :list="hotlist"></V-Right>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Right from "@/views/academic-headlines/common/news-flash-detail/right/index.vue";
import OneNewsFlash from "@/components/one-news-flash/index.vue";
import { QUERY } from "@/services/dao.js";
import{showTimeFormat, formatDate} from "@/js/common/index"

export default {
  data() {
    return {
      hidePageNav: true,
      pageNum: 0,
      pageSize: 10, //原来是10
      // lowerid: "", // 下一篇文章的id
      totlePage: 0,
      list: [], //快讯列表
      hotlist: [], //热门列表
    };
  },
  created: function () {
    this.init();
  },
  methods: {
    init() {
      this.id = Number(this.$route.query.id);
      this.searchInfo(this.pageSize, 0);
      this.getHotList();
    },
    ///快讯热榜
    async getHotList() {
      let info = await QUERY(
        "post",
        "",
        "ComprehensiveArticleList_2( limit: 10) {    id    img    title   createTime  hotNum }"
      );
      this.hotlist.splice(0, this.hotlist.length);
      for (let i = 0; i < info.data.ComprehensiveArticleList_2.length; i++) {
        this.hotlist.push({
          id: info.data.ComprehensiveArticleList_2[i].id*1,
          img: info.data.ComprehensiveArticleList_2[i].img,
          title: info.data.ComprehensiveArticleList_2[i].title,
          time: info.data.ComprehensiveArticleList_2[i].createTime,
          hotNum: info.data.ComprehensiveArticleList_2[i].hotNum,
        });
      }
    },
    //前一页
    prevPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    ////快讯详情
    async searchInfo(limit, offest) {
      let info = await QUERY(
        "post",
        "",
        "News(limit:" +
          limit +
          ", offset:" +
          offest +
          ", ) {    id    createTime    content        commentNum    collectNum    browseNum    authorName    accessory    img    intro        praiseNum    reference        title        hotNum  }  News_aggregate(where: {}) {    aggregate {      count    }  }"
      );
      this.list.splice(0, this.list.length);
      // console.log(info.data.News);
      for (let i = 0; i < info.data.News.length; i++) {
        this.list.push({
          id: info.data.News[i].id*1,
          title: info.data.News[i].title,
          content: info.data.News[i].intro,
          time: formatDate(Date.parse(info.data.News[i].createTime)),
          hotNum: info.data.News[i].hotNum
        });
        // 下一篇文章的id
        // this.lowerid.push({id:info.data.News[i+1].id})
        // console.log(this.lowerid)
       
      }
      this.totlePage = info.data.News_aggregate.aggregate.count;
      // this.lowerid=this.list[1].id
      // console.log(this.lowerid)

    },
  },
  components: {
    "V-Right": Right,
    "V-OneNewsFlash": OneNewsFlash,
  },
};
</script>

<style scoped>
@import url(~@/styles/page/index.css);
.container {
  width: 100%;
  text-align: center;
  background-color: #f7f7f7;
}

.container > .center {
  margin-top: 30px;
  width: 80%;
  margin: auto;
  padding-bottom: 30px;
  /* border: 1px solid red; */
}
.container > .center > .content > .left {
  padding: 30px;
  /* border: 1px solid seagreen; */
}
.container > .head > div,
.container > .center > .content {
  /* width: 90%; */
  width: 100%;
  display: inline-block;
  text-align: left;
  position: relative;
  /* border: 1px solid red; */
}
.container > .center > .content > .left {
  display: inline-block;
  width: 63%;
  background-color: #ffffff;
}
.container > .center > .content > .right {
  display: inline-block;
  width: 25%;
  vertical-align: top;
  margin-left: 3.6%;
  background-color: #ffffff;
  /* height: 1190px; */
  padding: 20px 5px 20px 20px;
  /* border: 1px solid greenyellow; */
}
.container > .center > .content > .right > div {
  display: inline-block;
}
</style>
